import React from "react"
import styled from "styled-components"

import RaisingABusiness from "../../../Elements/Images/RaisingABusiness"
import ScriptCallOut from "../../Shared/ScriptCallOut"
import { standardWrapper } from "../../../Utilities"

const RabIntroStyled = styled.div`
  .wrapper {
    ${standardWrapper};
  }

  .rabComputer {
    width: 100%;
  }

  .rabIntro {
    width: 100%;
    margin-top: 5rem;
    text-align: center;
  }
`

const RabIntro = () => {
  return (
    <RabIntroStyled>
      <div className="wrapper">
        <div className="rabComputer">
          <RaisingABusiness />
        </div>
        <div className="rabIntro">
          <ScriptCallOut
            content="<p class='call-out-text'>We want to share our challenges, triumphs and perspectives that
            we’ve had along the way. We will bring in experts that are really
            good at what they do to hear their stories. Because everyone has a
            story!</p>"
          />
        </div>
      </div>
    </RabIntroStyled>
  )
}

export default RabIntro
