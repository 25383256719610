import React from "react"
import styled from "styled-components"
import ScriptTitle from "../../Shared/ScriptTitle"
import { buttonOne, colors, standardWrapper } from "../../../Utilities"
import RaisingABusinessEpisode from "../../../Elements/Images/RaisingABusinessEpisode"

const FavEpisodesStyled = styled.section`
  background-color: #284031;
  .wrapper {
    ${standardWrapper};
  }

  .introTitle {
    width: 100%;
    text-align: center;

    h2 {
      color: ${colors.white};
    }
  }

  .introEpisodes {
    width: 100%;
    margin: 4rem auto;
  }

  .rabLinks {
    width: 100%;
    text-align: center;

    a {
      ${buttonOne};
    }

    p {
      margin-top: 3rem;
      color: ${colors.white};
    }
  }
`

const FavEpisodes = () => {
  return (
    <FavEpisodesStyled>
      <div className="wrapper">
        <div className="introTitle">
          <ScriptTitle title="<h2 class='title-script'>Past Episodes</h2>" />
        </div>

        <div className="introEpisodes">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://raisingabusiness.com/episodes"
          >
            <RaisingABusinessEpisode />
          </a>
        </div>

        <div className="rabLinks">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://raisingabusiness.com/episodes"
          >
            Listen to podcast
          </a>
          <ScriptTitle title="<p class='title-script'>Available on your favourite podcast player.</p>" />
        </div>
      </div>
    </FavEpisodesStyled>
  )
}

export default FavEpisodes
