import React from "react"
import styled from "styled-components"

import StandardWrapper from "../../Shared/StandardWrapper"
import ScriptTitle from "../../Shared/ScriptTitle"
import BodyCopy from "../../Shared/BodyCopy"
import { buttonOne } from "../../../Utilities"

const IntroStyled = styled.div`
  position: relative;
  margin-top: -5rem;
  margin-bottom: 5rem;
  z-index: 25;

  @media (min-width: 768px) {
    margin-top: -2.5rem;
  }

  @media (min-width: 1025px) {
    margin-top: -5vw;
  }

  .introWrapper {
    @media (min-width: 768px) {
    }
    @media (min-width: 1025px) {
      max-width: 65vw;
      margin-left: 10vw;
      padding: 0;
    }
  }

  .intro-content {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  .podcastLink {
    width: 100%;

    a {
      ${buttonOne};
    }
  }
`

const Intro = () => {
  return (
    <IntroStyled>
      <StandardWrapper modifierClass="introWrapper">
        <div className="intro-content">
          <ScriptTitle title="<h2 class='title-script'>Raising a Business Podcast.</h2>" />
          <BodyCopy content="<p>We created a podcast to discuss what it is like to Raise a Business, as business owners trying to build something amazing, as a husband and wife team, as people strengthening relationships and growing as human beings. Building a business is tough. But we can navigate it together. Through this platform we can share weekly how to grow your business, level up, market, pivot and fail forward.</p>" />
        </div>

        <div className="podcastLink">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://raisingabusiness.com/"
          >
            Listen to Podcast
          </a>
        </div>
      </StandardWrapper>
    </IntroStyled>
  )
}

export default Intro
