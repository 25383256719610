import React from "react"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HeaderScene from "../components/PageParts/Podcast/HeaderScene"
import Intro from "../components/PageParts/Podcast/Intro"
import RabIntro from "../components/PageParts/Podcast/RabIntro"
import FavEpisodes from "../components/PageParts/Podcast/FavEpisodes"

const podcast = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Raising a business Podcast - Switchback Creative"
        description="We created a podcast to discuss what it is like to Raise a Business, as business owners trying to build something amazing, as a husband and wife team, as people strengthening relationships and growing as human beings."
        location={props.location.pathname}
      />
      <HeaderScene />
      <Intro />
      <RabIntro />
      <FavEpisodes />
    </Layout>
  )
}

export default podcast
